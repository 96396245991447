<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-card-title>
            Generar Pedido
            <v-autocomplete
              :items="itemsCampana"
              item-text="name"
              item-value="id"
              class="ml-5 mt-5"
              label="Seleccionar Campaña"
              v-model="id_campana"
              @change="loadRSeniorS()"
            >
            </v-autocomplete>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchS"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row>
            <v-col cols="12" md="4"> </v-col>
          </v-row>
          <v-data-table
            :headers="headersS"
            :items="itemsRSeniorS"
            :search="searchS"
            @click:row="handleClicks"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2 mx-auto"
                @click="
                  loadSeniorPro(
                    item.id_proveedor,
                    item.proveedor,
                    item.compra_pro
                  )
                "
              >
                mdi-file-pdf
              </v-icon>
              <v-icon
                class="mr-2 mx-auto"
                @click="
                  loadSeniorProPrecio(
                    item.id_proveedor,
                    item.proveedor,
                    item.compra_pro
                  )
                "
              >
                mdi-cash-check
              </v-icon>
            </template>
            <template slot="body.append">
              <tr class="pink--text">
                <th colspan="1" class="title">Totales</th>

                <th colspan="1" class="title">
                  $ {{ sumFieldSS("subtotal_prov") }}
                </th>
                <th colspan="1" class="title">
                  $ {{ sumFieldSS("compra_pro") }}
                </th>
                <th colspan="1" class="title">
                  $ {{ sumFieldSS("ganancia") }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogcostos" width="1000">
      <v-card>
        <v-card-title>
          COSTO DE {{ co_name }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                v-model="co_monto"
                label="Monto"
                @keyup="calco"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                v-model="co_igv"
                disabled
                label="IGV"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                v-model="co_total"
                disabled
                label="Total"
              ></v-text-field>
            </v-col>
            <v-col></v-col>
            <v-col></v-col>
          </v-row>

          <v-btn block color="primary" @click="addCostos()" rounded
            >Guardar</v-btn
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogcostos = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import VueHtml2pdf from "vue-html2pdf";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
export default {
  name: "RSenior",
  components: {
    VueHtml2pdf,
  },
  htmlToPdfOptions: {
    margin: 0,

    filename: `hehehe.pdf`,

    image: {
      type: "jpeg",
      quality: 0.98,
    },

    enableLinks: false,

    html2canvas: {
      scale: 1,
      useCORS: true,
    },

    jsPDF: {
      unit: "in",
      format: "a4",
      orientation: "portrait",
    },
  },
  data: () => ({
    search: "",
    searchS: "",
    searchSS: "",
    senior: "",
    itemsRSenior: [],
    itemsRSeniorS: [],
    itemsRSeniorSS: [],
    itemsCampana: [],
    itemsCostos: [],
    itemsProvDetails: [],
    itemsSeniorPro: [],
    id: "",
    id_campana: "",
    name: "",
    dialogrs: "",
    description: "",
    status: true,
    statusData: 1,
    dialogcostos: false,
    gl_base: "",
    gl_igv_base: "",
    proveedor: "",
    columns: [],

    co_name: "",
    co_monto: "",
    co_igv: "",
    co_total: "",
    co_id: "",
    comprad: "",

    doc: "",

    headers: [
      {
        text: "Junior",
        align: "start",

        value: "junior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],

    headersS: [
      {
        text: "Proveedor",
        align: "start",
        value: "proveedor",
      },
      { text: "Sub Total", value: "subtotal_prov" },
      { text: "Sub Total Compra", value: "compra_pro" },
      { text: "Ganancia", value: "ganancia" },
      { text: "Acciones", value: "actions" },
    ],

    columns: [
      {
        label: "Junior",
        align: "start",
        field: "junior",
      },
      { label: "Senior", field: "senior" },
      { label: "SubTotal", field: "subtotal" },
      { label: "Sub Total Compra", field: "compra_pro" },
      { text: "Ganancia", value: "ganancia" },
    ],

    headersSS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],
    todos: [
      { title: "todo 1", description: "description 1" },
      { title: "todo 2", description: "description2" },
      { title: "todo 3", description: "description 3" },
      { title: "todo 4", description: "description 4" },
      { title: "todo 5", description: "description 5" },
    ],
  }),
  mounted() {
    this.loadCampanas();
    this.loadRSenior();
    //this.loadRSeniorS();
  },
  methods: {
    loadCampanas() {
      axios.get(process.env.VUE_APP_API_URL + "campana_all").then((result) => {
        this.itemsCampana = result.data;
      });
    },
    loadSeniorPro(id, proveedor, compra) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/senior/proveedor/" + id)
        .then((result) => {
          this.itemsSeniorPro = result.data;

          this.itemsSeniorPro.forEach((item, index) => {
            var pos = parseInt(index) + 1;

            if (this.itemsSeniorPro.length == pos) {
              this.generarPdf(proveedor, compra);
            }
          });
        });
    },
    loadSeniorProPrecio(id, proveedor, compra) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/senior/proveedor/" + id)
        .then((result) => {
          this.itemsSeniorPro = result.data;

          this.itemsSeniorPro.forEach((item, index) => {
            var pos = parseInt(index) + 1;

            if (this.itemsSeniorPro.length == pos) {
              this.generarPdfPrecio(proveedor, compra);
            }
          });
        });
    },
    generarPdf(proveedor, compra) {
      var doc = new jsPDF("p", "pt");
      var pos;
      var i = 1;
      this.comprad = compra;

      doc.autoTable({
        body: [
          [
            {
              content: "PROVEEDOR " + proveedor,
              colSpan: 2,
              rowSpan: 2,
              styles: { halign: "left", fontSize: 12, fontStyle: "bold" },
            },
          ],
        ],
      });

      this.itemsSeniorPro.forEach((item, index) => {
        pos = parseInt(index + 1);
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "rep/campana/details/pro/cam/" +
              item.id_proveedor +
              "/" +
              item.id_senior + "/" + this.id_campana
          )
          .then((result) => {
            this.itemsProvDetails = result.data;
            localStorage.setItem(
              "DataDetails" + index,
              JSON.stringify(result.data)
            );
          });

        doc.autoTable({
          body: [
            [
              {
                content: "ETIQUETADO",
                colSpan: 2,
                rowSpan: 2,
                styles: { halign: "left", fontSize: 18 },
              },
            ],
          ],
        });

        doc.autoTable({
          body: [
            [
              {
                content: "PIC CARGO / " + item.senior,
                colSpan: 2,
                rowSpan: 2,
                styles: { halign: "left", fontSize: 24, fontStyle: "bold" },
              },
            ],
          ],
        });

        const data = JSON.parse(localStorage.getItem("DataDetails" + index));

        doc.autoTable(
          (this.columns = [
            { title: "Código", dataKey: "codigo_producto" },
            { title: "Producto", dataKey: "producto" },
            { title: "Unidades", dataKey: "cantidad" },
            { title: "Set", dataKey: "set" },
            { title: "Observación", dataKey: "observacion" },
          ]),
          data,
          {
            margin: { top: 90 },
          }
        );

        if (this.itemsSeniorPro.length == pos) {
          doc.autoTable({
            body: [
              [
                {
                  content: "TOTAL: USD " + this.comprad,
                  colSpan: 2,
                  rowSpan: 2,
                  styles: { halign: "left", fontSize: 18 },
                },
              ],
            ],
          });

          doc.save("LISTA DE " + proveedor + ".pdf");
          localStorage.removeItem("DataDetails");
        }
      });
      localStorage.removeItem("DataDetails");
    },

    generarPdfPrecio(proveedor, compra) {
      var doc = new jsPDF("p", "pt");
      var pos;
      this.comprad = compra;
      doc.autoTable({
        body: [
          [
            {
              content: "PROVEEDOR " + proveedor,
              colSpan: 2,
              rowSpan: 2,
              styles: { halign: "left", fontSize: 12, fontStyle: "bold" },
            },
          ],
        ],
      });

      this.itemsSeniorPro.forEach((item, index) => {
        pos = parseInt(index + 1);
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "rep/campana/details/pro/" +
              item.id_proveedor +
              "/" +
              item.id_senior
          )
          .then((result) => {
            this.itemsProvDetails = result.data;
            localStorage.setItem(
              "DataDetails" + index,
              JSON.stringify(result.data)
            );
          });

        doc.autoTable({
          body: [
            [
              {
                content: "ETIQUETADO",
                colSpan: 2,
                rowSpan: 2,
                styles: { halign: "left", fontSize: 18 },
              },
            ],
          ],
        });

        doc.autoTable({
          body: [
            [
              {
                content: "PIC CARGO / " + item.senior,
                colSpan: 2,
                rowSpan: 2,
                styles: { halign: "left", fontSize: 24, fontStyle: "bold" },
              },
            ],
          ],
        });

        const data = JSON.parse(localStorage.getItem("DataDetails" + index));

        doc.autoTable(
          (this.columns = [
            { title: "Código", dataKey: "codigo_producto" },
            { title: "Producto", dataKey: "producto" },
            { title: "Unidades", dataKey: "cantidad" },
            { title: "Set", dataKey: "set" },
            { title: "Precio", dataKey: "precio_compra" },
            { title: "Observación", dataKey: "observacion" },
          ]),
          data,
          {
            margin: { top: 90 },
          }
        );
        if (this.itemsSeniorPro.length == pos) {
          doc.autoTable({
            body: [
              [
                {
                  content: "TOTAL: USD " + this.comprad,
                  colSpan: 2,
                  rowSpan: 2,
                  styles: { halign: "left", fontSize: 18 },
                },
              ],
            ],
          });
          doc.save("LISTA DE " + proveedor + ".pdf");
          localStorage.removeItem("DataDetails");
        }
      });
      localStorage.removeItem("DataDetails");
    },
    /* async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            );
          }
        })
        .save();
    }, */
    loadProvDetails(id, id_senior) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "rep/campana/details/pro/" +
            id +
            "/" +
            id_senior
        )
        .then((result) => {
          this.itemsProvDetails = result.data;
        });
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    calco() {
      var monto = parseFloat(this.co_monto);
      var igv = parseFloat(this.co_igv);
      var total = parseFloat(this.co_total);

      igv = monto * 0.18;
      total = monto + igv;

      this.co_igv = igv.toFixed(2);
      this.co_total = total.toFixed(2);
    },
    loadCostos() {
      axios
        .get(process.env.VUE_APP_API_URL + "campana/costos/" + this.id_campana)
        .then((result) => {
          this.itemsCostos = result.data;
        });
    },
    loadRSenior() {
      axios.get(process.env.VUE_APP_API_URL + "rep/senior").then((result) => {
        this.itemsRSenior = result.data;
      });
    },
    handleClick(row) {
      this.loadRSeniorSS(row.id);
    },

    viewCostos(id, name) {
      this.co_id = id;
      this.co_name = name;
      this.dialogcostos = true;
    },

    loadRSeniorS() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "rep/proveedor/campana/" +
            this.id_campana
        )
        .then((result) => {
          this.itemsRSeniorS = result.data;
          this.loadCostos();
          this.costoGlobal();
        });
    },
    loadRSeniorSS(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/junior/" + id)
        .then((result) => {
          this.dialogrs = true;
          this.senior = result.data[0].senior;
          this.itemsRSeniorSS = result.data;
        });
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "RSenior/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.name = result.data[0].name;
          this.description = result.data[0].description;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    addData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "RSenior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "RSenior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    addCostos() {
      let data = {
        monto: this.co_monto,
        igv: this.co_igv,
        total: this.co_total,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "costos/monto/" + this.co_id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Monto actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanCosto();
            this.loadCostos(this.id_campana);
            this.dialogcostos = false;
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    cleanCosto() {
      this.co_monto = "";
      this.co_igv = "";
      this.co_total = "";
    },

    costoGlobal() {
      this.gl_base = (this.sumFieldSS("subtotal_prov") / 1.118).toFixed(2);
      this.gl_igv_base = (
        this.sumFieldSS("subtotal_prov") - this.gl_base
      ).toFixed(2);
    },

    lockitem(id) {
      let data = {
        status: 2,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/lock/junior/" + id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSeniorS();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    unlockitem(id) {
      let data = {
        status: 1,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/lock/junior/" + id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSeniorS();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    sumField(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSenior.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorSS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldCo(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsCostos.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldSS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminada, la orden no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "¿Está completamente seguro de Eliminar?",
            text: "Una vez eliminada, la orden NO se podrá recuperar",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar",
          }).then((result) => {
            if (result.value) {
              axios
                .delete(process.env.VUE_APP_API_URL + "productos/junior/" + id)
                .then((result) => {
                  console.log(result.data);
                  if (result.data.boolean == true) {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Orden eliminada con éxito",
                      showConfirmButton: true,
                      timer: 5000,
                    });
                    this.loadRSeniorS();
                  } else {
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      title: "Oops...",
                      text: result.data.error.sqlMessage,
                      showConfirmButton: true,
                      timer: 8000,
                    });
                  }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelado", "La orden no fue eliminado", "error");
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "La orden no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.description = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
