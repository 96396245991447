var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Generar Pedido "),_c('v-autocomplete',{staticClass:"ml-5 mt-5",attrs:{"items":_vm.itemsCampana,"item-text":"name","item-value":"id","label":"Seleccionar Campaña"},on:{"change":function($event){return _vm.loadRSeniorS()}},model:{value:(_vm.id_campana),callback:function ($$v) {_vm.id_campana=$$v},expression:"id_campana"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchS),callback:function ($$v) {_vm.searchS=$$v},expression:"searchS"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headersS,"items":_vm.itemsRSeniorS,"search":_vm.searchS},on:{"click:row":_vm.handleClicks},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 mx-auto",on:{"click":function($event){return _vm.loadSeniorPro(
                  item.id_proveedor,
                  item.proveedor,
                  item.compra_pro
                )}}},[_vm._v(" mdi-file-pdf ")]),_c('v-icon',{staticClass:"mr-2 mx-auto",on:{"click":function($event){return _vm.loadSeniorProPrecio(
                  item.id_proveedor,
                  item.proveedor,
                  item.compra_pro
                )}}},[_vm._v(" mdi-cash-check ")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"pink--text"},[_c('th',{staticClass:"title",attrs:{"colspan":"1"}},[_vm._v("Totales")]),_c('th',{staticClass:"title",attrs:{"colspan":"1"}},[_vm._v(" $ "+_vm._s(_vm.sumFieldSS("subtotal_prov"))+" ")]),_c('th',{staticClass:"title",attrs:{"colspan":"1"}},[_vm._v(" $ "+_vm._s(_vm.sumFieldSS("compra_pro"))+" ")]),_c('th',{staticClass:"title",attrs:{"colspan":"1"}},[_vm._v(" $ "+_vm._s(_vm.sumFieldSS("ganancia"))+" ")])])])],2)],1)],1)],1),_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.dialogcostos),callback:function ($$v) {_vm.dialogcostos=$$v},expression:"dialogcostos"}},[_c('v-card',[_c('v-card-title',[_vm._v(" COSTO DE "+_vm._s(_vm.co_name)+" "),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","label":"Monto"},on:{"keyup":_vm.calco},model:{value:(_vm.co_monto),callback:function ($$v) {_vm.co_monto=$$v},expression:"co_monto"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","disabled":"","label":"IGV"},model:{value:(_vm.co_igv),callback:function ($$v) {_vm.co_igv=$$v},expression:"co_igv"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","disabled":"","label":"Total"},model:{value:(_vm.co_total),callback:function ($$v) {_vm.co_total=$$v},expression:"co_total"}})],1),_c('v-col'),_c('v-col')],1),_c('v-btn',{attrs:{"block":"","color":"primary","rounded":""},on:{"click":function($event){return _vm.addCostos()}}},[_vm._v("Guardar")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogcostos = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }